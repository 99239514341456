<script setup>
import {computed, ref} from "vue";
import {chevronDown} from "ionicons/icons";
import {Link} from '@inertiajs/vue3'
import {route} from "ziggy-js";

const props = defineProps({
    title: String,
    url: String,
    collapsing: Boolean,
    right: Boolean,
    method: {
        type: String,
        default: 'get'
    },
    type: {
        type: String,
        default: null
    },
    otherCategory: {
        type: Array,
        default: null
    },
    active: {
        type:Boolean,
        default: false
    }
})
const showingSubMenus = ref(false);

const isActive = computed(() => {
    if (props.collapsing) {
        return props.otherCategory.some(item => route().current('category', item.slug))
    } else if(props.type) {
        return route().current('category', props.type)
    } else {
        if(props.right) {
            return route().current('home')
        } else {
            return props.active
        }

    }

})

</script>

<template>
    <li class="border-b border-dashed border-gray-200 last:border-none text-gray-300 text-sm
  hover:text-blue-400 hover:border-blue-400 uppercase">
        <div v-if="collapsing" :class="[{'flex-row-reverse': !right}, {'text-blue-400 font-medium': isActive}]"
              class="flex items-center justify-end py-3 cursor-pointer"
              @click="showingSubMenus = !showingSubMenus"
        >
            <ion-icon :icon="chevronDown" class="text-2xl transition-transform" :class="{
                'rotate-180' : showingSubMenus,
            }"></ion-icon>
            <span class="mx-2">{{ title }}</span>
            <slot/>
        </div>
        <Link v-else :class="[{'flex-row-reverse': !right}, {'text-blue-400 [&>*]:fill-blue-400 group-hover:[&>*]:!fill-blue-400 font-medium': isActive}]"
              :href="url"
              :method="method"
              class="flex items-center justify-end py-3"
        >
            <span class="mx-2">{{ title }}</span>
            <slot/>
        </Link>
        <slot v-if="showingSubMenus" name="subMenu"/>
    </li>
</template>
