<script setup>
import {Link} from "@inertiajs/vue3";
import {route} from "ziggy-js";

const props = defineProps({
    contentClass: {
        type: Array,
        default: []
    },
    productId: {
        type: Number
    }
})

</script>

<template>
    <div :class="[...contentClass]" class="border-b border-slate-300 dark:border-slate-700 border-dashed -ml-2.5 -mr-2.5">
        <div class="px-2.5 tracking-wider">
            <div
                class="px-2.5 py-1.25 text-red-500 border-b border-slate-300 dark:border-slate-700 w-fit border-dashed text-sm">
                {{ $t('Whoops!') }} :(
            </div>
            <div class="dark:text-slate-200 px-2.5 pt-1.5 pb-2.5  text-xs ">{{ $t('Seems like we don’t have this yet.') }}
                <br>
                {{
                    $t('Try to refine your request. Or click')
                }}
                <Link
                    :href="props.productId ? route('user.offers.selectLot', {product_id: props.productId}) : route('user.offers.selectProduct')"
                      class="text-blue-600 underline cursor-pointer">
                    {{ $t('HERE') }}
                </Link>
                , {{ $t('to add new item to catalog.') }}
            </div>
        </div>
    </div>

</template>

<style scoped>

</style>
