<script setup>
import HorizontalMenuItem from "@/Components/Menus/HorizontalMenuItem.vue";
import {route} from "ziggy-js";
import {usePage} from "@inertiajs/vue3";
import Ellips from "@/Components/Icons/Ellips.vue";
import {computed} from "vue";

const page = usePage();
const categoriesWithSubcategories = computed(() => {
    const categories = page.props.categories?.reduce((acc, category) => {
        if (!category.parent_id) {
            acc[category.id] = {...category};
            acc[category.id].subcategories = [];
        }
        return acc;
    }, {});
    page.props.categories.forEach(category => {
        if (category.parent_id) {
            categories[category.parent_id].subcategories.push(category);
        }
    });
    return categories;
});

</script>

<template>
    <ul class="flex-row">
        <template v-for="category in categoriesWithSubcategories">
            <HorizontalMenuItem v-if="category.subcategories.length === 0" :url="route('category', category.slug)"
                                :title="category.title"
                                :type="category.slug"/>
            <HorizontalMenuItem v-else :other-category="category.subcategories" :title="category.title" collapsing
                                url="#">
                <template #subMenu>
                    <ul class="list-disc">
                        <template v-for="subCategory in category.subcategories">
                            <HorizontalMenuItem :title="subCategory.title" :url="route('category', subCategory.slug)"
                                                class="gap-1.5 !justify-end py-1.5 !px-0 group"
                                                :type="subCategory.slug">
                                <template #doth>
                                    <Ellips class="group-hover:fill-blue-400"/>
                                </template>
                            </HorizontalMenuItem>
                        </template>
                    </ul>
                </template>
            </HorizontalMenuItem>
        </template>
    </ul>
</template>
