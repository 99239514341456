<script setup>

import {getCurrencies} from "@/constants.js";
import {clickOutSide as vClickOutside} from "@/vClickOutside.js";
import Rubel from "@/Components/Icons/Rubel.vue";
import Usd from "@/Components/Icons/Usd.vue";
import Arrow from "@/Components/Icons/Arrow.vue";
import {activeCurrency, onSelectCurrency} from "@/helper.js";
import {ref} from "vue";

const showCurrency = ref(false)
const hideCurrency = () => {
    if (showCurrency.value) {
        showCurrency.value = false
    }
}

</script>

<template>
    <div v-click-outside="hideCurrency" class="relative " @click="showCurrency = !showCurrency">
        <div class="flex gap-2 text-sm items-center group">
            <Rubel v-if="activeCurrency?.code === 'RUB'" class="!stroke-white group-hover:!stroke-blue-400"/>
            <Usd v-else class="!stroke-white group-hover:!stroke-blue-400"/>
            <span class="cursor-pointer group-hover:text-blue-400 transition-colors leading-full">{{
                    activeCurrency.code
                }}</span>
            <arrow
                class="!stroke-white !fill-white w-3 group-hover:!stroke-blue-400 group-hover:!fill-blue-400 transition-colors duration-100"/>
        </div>
        <div v-if="showCurrency" class="absolute z-10 top-10 left-1/2 -translate-x-1/2">
            <div class=" flex items-center px-2.5 bg-deep-blue">
                <ul>
                    <li class="py-1.5 hover:text-blue-400 transition-all uppercase cursor-pointer text-sm"
                        v-for="itemCurrency in getCurrencies()"
                        @click="onSelectCurrency(itemCurrency)"
                    >{{ itemCurrency.code }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
