<script setup>
import ModalNew from "@/Components/ModalNew.vue";
import Button from "@/Components/Button.vue";
import {ref} from "vue";
import {usePage} from "@inertiajs/vue3";

const page = usePage();
const is_referral = ref(!!page.props.referral_modal);

</script>
<template>
    <ModalNew :show="is_referral" max-width="sm" @close="is_referral = false">
        <div class="py-5 px-3 flex flex-col gap-3">
            <div class="w-full flex justify-end pb-2">
                <ion-icon name="closeSharp"
                          class="text-xl bg-slate-200 dark:bg-deep-blue dark:fill-slate-200 cursor-pointer rounded-lg p-0.5"
                          @click="is_referral = false"></ion-icon>
            </div>
            <p class="flex justify-center font-sans text-xl font-medium pb-1 dark:text-slate-200">
                {{ $t('Thanks for signing up!') }}
            </p>
            <div class="px-2.5 pb-2 flex">
                <div>
                    <ion-icon class="text-3xl text-red-500" name="alertCircle"></ion-icon>
                </div>
                <p class="ml-2.5 text-sm leading-4 dark:text-slate-200">
                    {{
                        $t('To credit the 1 USD bonus to your account, you need to subscribe to our telegram bot (you can do this later in your personal account settings).')
                    }}</p>
            </div>
            <div class="flex m-auto pb-2">
                <a :href="$page.props.referral_modal.telegram_enable_url" target="_blank">
                    <Button
                        :text="$t('Subscribe')"
                        class="text-sm w-m-min font-medium"
                        type="primary"
                    ></Button>
                </a>
            </div>
            <div class="px-2.5 flex pb-2">
                <div>
                    <ion-icon class="text-base text-red-500" name="alertCircle"></ion-icon>
                </div>
                <p class="ml-2.5 text-sm leading-4 dark:text-slate-200">{{ $t('settings.telegram_start_remind') }}</p>
            </div>
            <img alt="" src="/assets/img/register/referral2.png">
        </div>
    </ModalNew>
</template>
