<script setup>
import {Link, router, usePage} from '@inertiajs/vue3';
import ResponsiveNavLink from '@/Components/ResponsiveNavLink.vue';
import {computed, inject, ref, watch} from "vue";
import SearchInput from "@/Components/SearchInput.vue";
import Menu from "@/Components/Icons/Menu.vue";
import Logo from "@/Components/Icons/Logo.vue";
import SideBarMenu from "@/Components/Menus/SideBarMenu.vue";
import ProfileMenu from "@/Components/Menus/ProfileSidebarMenu.vue";
import HorizontalMenu from "@/Components/Menus/HorizontalMenu.vue";
import {route} from "ziggy-js";
import {chatbubbleEllipses, helpCircle, person} from "ionicons/icons";
import NavLink from "@/Components/NavLink.vue";
import {storeToRefs} from "pinia";
import {useThread} from "@/Store/Thread.js";
import {HelpDeskUrl} from "@/constants.js";
import GoogleOneTap from "@/Pages/Auth/GoogleOneTap.vue";
import {useUser} from "@/Store/User.js";
import SidebarLangSwitch from "@/Components/SidebarLangSwitch.vue";
import MobileSidebarLangSwitch from "@/Components/MobileSidebarLangSwitch.vue";
import SidebarCurrencySwitch from "@/Components/SidebarCurrencySwitch.vue";
import LightTheme from "@/Components/Icons/LightTheme.vue";
import DarkTheme from "@/Components/Icons/DarkTheme.vue";

const logout = () => {
    router.post(route('logout'));
};
const showingNavigationDropdown = ref(false);

const switchToTeam = (team) => {
    router.put(route('current-team.update'), {
        team_id: team.id,
    }, {
        preserveState: false,
    });
};
const page = usePage()
const isAuthenticated = computed(() => page.props.auth?.user ?? false)
const showTeamControls = false;
const showSideBarMenu = ref(false)
const showProfileMenu = ref(false)
const searchFocus = ref(false)
const toggleSearchFocus = () => searchFocus.value = false
const updateChannel = inject('updateChannel')
const updateMessageChannel = inject('updateMessageChannel'),
    {hasNewMessage} = storeToRefs(useThread())

watch(isAuthenticated, () => {
    updateChannel(page.props.auth?.user)
    updateMessageChannel(page.props.auth?.user)
})
const userStore = useUser();
</script>

<template>
    <nav class="bg-deep-blue border-b border-gray-100 dark:border-deep-blue">
        <!-- Primary Navigation Menu -->
        <div class="max-w-7xl mx-auto px-2 pt-2 sm:px-6 sm:py-0 lg:px-3">
            <div class="grid grid-cols-12 sm:flex">
                <div class="flex justify-self-end col-span-1 pl-2 lg:hidden">
                    <!-- Logo -->
                    <div class="shrink-0 flex items-center" data-drawer-target="default-sidebar"
                         data-drawer-toggle="default-sidebar">
                        <button class="w-8 h-8 flex border border-gray-300 rounded-full" type="button"
                                @click="showSideBarMenu = true">
                            <Menu class="m-auto"/>
                        </button>
                        <SideBarMenu v-model="showSideBarMenu"/>
                    </div>
                </div>
                <Link class="flex justify-self-end col-start-5 col-end-7 sm:px-2 sm:mx-5 lg:mx-0" :href="route('home')">
                    <Logo class="self-center"/>
                </Link>
                <!-- Navigation Links -->

                <HorizontalMenu :style="searchFocus ? 'width: 0' : 'width: 100%'"
                                :class="{'mr-4': !searchFocus}"
                                class="hidden !max-w-min lg:flex transition-all w-full"/>
                <!-- Hamburger -->
                <div v-if="isAuthenticated"
                     class="flex items-center col-start-auto col-end-13 justify-self-end sm:order-last sm:pl-5 shrink-0 gap-2">
                    <div class=" hidden lg:flex gap-2 text-white items-center">
                        <SidebarLangSwitch/>
                        <SidebarCurrencySwitch/>
                    </div>
                    <MobileSidebarLangSwitch/>
                    <div @click="userStore.toggleTheme()" class="mr-2 ml-2 lg:flex gap-2 text-white items-center cursor-pointer">
                        <LightTheme class="fill-white hover:fill-blue-400 transition-all duration-200"  v-if="userStore.theme === 'dark'"/>
                        <DarkTheme class="fill-white hover:fill-blue-400 transition-all duration-200"  v-if="userStore.theme === 'light'"/>
                    </div>

                    <NavLink class="flex relative" :href="route('threads.index')">
                        <ion-icon :icon="chatbubbleEllipses"
                                  class="text-2xl leading-full text-white hover:text-blue-400 transition-colors duration-200"/>
                        <div v-if="hasNewMessage || $page.props.has_new_messages"
                             class="bg-red-500 rounded-full w-2 h-2 absolute top-0 right-0"></div>
                    </NavLink>
                    <button
                        class="inline-flex items-center min-w-max justify-center p-2 rounded-md text-gray-400 transition duration-150 ease-in-out "
                        @click="showProfileMenu = !showProfileMenu"
                    >
                        <img :src="page.props.auth.user.profile_photo_url" alt="avatar"
                             class="rounded-full w-8 h-8 relative border border-gray-300 hover:brightness-90 transition-all"/>
                    </button>
                    <ProfileMenu v-model="showProfileMenu"/>
                </div>
                <div v-else
                     class="flex items-center justify-center col-start-auto col-end-13 sm:order-last pr-2 sm:pl-5 justify-self-end gap-2.5">

                    <div class="hidden lg:flex gap-2 text-white items-center">
                        <SidebarLangSwitch/>
                        <SidebarCurrencySwitch/>
                    </div>
                    <MobileSidebarLangSwitch/>
                    <div @click="userStore.toggleTheme()" class="mr-2 ml-2 lg:flex gap-2 text-white items-center cursor-pointer">
                        <LightTheme class="fill-white hover:fill-blue-400 transition-all duration-200" v-if="userStore.theme === 'dark'"/>
                        <DarkTheme class="fill-white hover:fill-blue-400 transition-all duration-200"  v-if="userStore.theme === 'light'"/>
                    </div>
                    <a :href="HelpDeskUrl" class="flex" target="_blank">
                        <ion-icon :icon="helpCircle"
                                  class="text-2xl leading-full text-white hover:text-blue-400 transition-colors duration-200"/>
                    </a>
                    <NavLink :href="route('login')"
                             class="rounded-full w-8 h-8 border border-gray-400 flex justify-center items-center bg-black hover:bg-blue-400 dark:hover:bg-blue-400 transition-colors duration-200">
                        <ion-icon :icon="person" class="text-2xl text-slate-100"/>
                    </NavLink>
                </div>
                <div class="col-span-12 grow w-full transition-all duration-500">
                    <SearchInput class="transition-all" @on-focus="searchFocus = true" @on-close="toggleSearchFocus"/>
                </div>
            </div>
        </div>
        <!-- Responsive Navigation Menu -->
        <div v-if="isAuthenticated" :class="{'block': showingNavigationDropdown, 'hidden': ! showingNavigationDropdown}"
             class="sm:hidden">
            <div class="pt-2 pb-3 space-y-1">
                <ResponsiveNavLink :href="route('home')" :active="route().current('home')">
                    Dashboard
                </ResponsiveNavLink>
            </div>

            <!-- Responsive Settings Options -->
            <div class="pt-4 pb-1 border-t border-gray-200">
                <div class="flex items-center px-4">
                    <div v-if="$page.props.jetstream.managesProfilePhotos" class="shrink-0 mr-3">
                        <img class="h-10 w-10 rounded-full object-cover" :src="$page.props.auth.user.profile_photo_url"
                             :alt="$page.props.auth.user.name">
                    </div>

                    <div>
                        <div class="font-medium text-base text-gray-800">
                            {{ $page.props.auth.user.name }}
                        </div>
                        <div class="font-medium text-sm text-gray-500">
                            {{ $page.props.auth.user.email }}
                        </div>
                    </div>
                </div>

                <div class="mt-3 space-y-1">
                    <ResponsiveNavLink :href="route('profile.show')" :active="route().current('profile.show')">
                        Profile
                    </ResponsiveNavLink>

                    <ResponsiveNavLink v-if="$page.props.jetstream.hasApiFeatures" :href="route('api-tokens.index')"
                                       :active="route().current('api-tokens.index')">
                        API Tokens
                    </ResponsiveNavLink>

                    <!-- Authentication -->
                    <form method="POST" @submit.prevent="logout">
                        <ResponsiveNavLink as="button">
                            Log Out
                        </ResponsiveNavLink>
                    </form>

                    <!-- Team Management -->
                    <template v-if="$page.props.jetstream.hasTeamFeatures && showTeamControls">
                        <div class="border-t border-gray-200"/>

                        <div class="block px-4 py-2 text-xs text-gray-400">
                            Manage Team
                        </div>

                        <!-- Team Settings -->
                        <ResponsiveNavLink :href="route('teams.show', $page.props.auth.user.current_team)"
                                           :active="route().current('teams.show')">
                            Team Settings
                        </ResponsiveNavLink>

                        <ResponsiveNavLink v-if="$page.props.jetstream.canCreateTeams" :href="route('teams.create')"
                                           :active="route().current('teams.create')">
                            Create New Team
                        </ResponsiveNavLink>

                        <!-- Team Switcher -->
                        <template v-if="$page.props.auth.user.all_teams.length > 1">
                            <div class="border-t border-gray-200"/>

                            <div class="block px-4 py-2 text-xs text-gray-400">
                                Switch Teams
                            </div>

                            <template v-for="team in $page.props.auth.user.all_teams" :key="team.id">
                                <form @submit.prevent="switchToTeam(team)">
                                    <ResponsiveNavLink as="button">
                                        <div class="flex items-center">
                                            <svg v-if="team.id === $page.props.auth.user.current_team_id"
                                                 class="mr-2 h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg"
                                                 fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                                 stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                      d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                            </svg>
                                            <div>{{ team.name }}</div>
                                        </div>
                                    </ResponsiveNavLink>
                                </form>
                            </template>
                        </template>
                    </template>
                </div>
            </div>
        </div>
        <!--        <div v-else :class="{'block': showingNavigationDropdown, 'hidden': ! showingNavigationDropdown}">-->
        <!--            <Link :href="route('login')" as="button" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">Log in</Link>-->
        <!--            <Link v-if="canRegister" :href="route('register')" class="ml-4 font-semibold text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white focus:outline focus:outline-2 focus:rounded-sm focus:outline-red-500">Register</Link>-->
        <!--        </div>-->
        <GoogleOneTap/>
    </nav>
</template>

