<script setup>

</script>

<template>
    <svg width="14" height="12" viewBox="0 0 14 12" class="fill-gray-300" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.962427 4.99468C4.72052 3.35734 7.2265 2.2779 8.48037 1.75637C12.0604 0.2673 12.8043 0.00863244 13.2892 9.107e-05C13.3959 -0.00178751 13.6343 0.0246415 13.7887 0.14997C13.9192 0.255795 13.955 0.39875 13.9722 0.499084C13.9894 0.599418 14.0108 0.827981 13.9938 1.00657C13.7998 3.045 12.9603 7.99172 12.5332 10.2748C12.3525 11.2408 11.9967 11.5648 11.6522 11.5965C10.9036 11.6654 10.3351 11.1017 9.61007 10.6264C8.47546 9.88267 7.83448 9.41969 6.73315 8.69392C5.46037 7.85518 6.28546 7.39419 7.01082 6.64081C7.20064 6.44365 10.4991 3.44345 10.5629 3.17128C10.5709 3.13724 10.5783 3.01036 10.503 2.94336C10.4276 2.87637 10.3163 2.89928 10.236 2.9175C10.1223 2.94332 8.3097 4.14135 4.79841 6.51158C4.28392 6.86486 3.81791 7.037 3.40039 7.02798C2.9401 7.01803 2.05469 6.76772 1.39648 6.55376C0.589162 6.29134 -0.0524811 6.15259 0.00339163 5.7069C0.0324936 5.47476 0.352172 5.23735 0.962427 4.99468Z" />
    </svg>

</template>

<style scoped>

</style>
