<script setup>
import Arrow from "@/Components/Icons/Arrow.vue";
import {clickOutSide as vClickOutside} from "@/vClickOutside.js";
import {globe} from "ionicons/icons";
import {getLangs} from "@/constants.js";
import {storeToRefs} from "pinia";
import {useUser} from "@/Store/User.js";
import {onSelectLang} from "@/helper.js";
import {ref} from "vue";

const showLangs = ref(false);
const storeUser = useUser();
const {lang} = storeToRefs(storeUser);
const hideLangs = () => {
    if (showLangs.value) {
        showLangs.value = false
    }
}

</script>
<template>
    <div v-click-outside="hideLangs" class="relative" @click="showLangs = !showLangs">
        <div class="flex gap-2 text-sm items-center group">
            <ion-icon :icon="globe"
                      class="text-xl align-middle group-hover:text-blue-400 transition-colors"></ion-icon>
            <span class="cursor-pointer group-hover:text-blue-400 transition-colors">{{
                    lang.short
                }}</span>
            <arrow
                class="!stroke-white !fill-white w-3 group-hover:!stroke-blue-400 group-hover:!fill-blue-400 transition-colors duration-100"/>
        </div>
        <div v-if="showLangs" class="absolute z-10 top-10">
            <div class=" flex items-center px-2.5 bg-deep-blue">
                <ul>
                    <li v-for="itemLang in getLangs()"
                        class="py-1.5 hover:text-blue-400 transition-all uppercase cursor-pointer text-sm"
                        @click="onSelectLang(itemLang)"
                    >{{ itemLang.name }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
